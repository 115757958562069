<template>
  <div class="restaurant-page">
    <!-- 门店信息 -->
    <section class="store-info">
      <h2 class="section-title">门店信息</h2>
      <div class="store-card">
        <div class="store-header" @click="goToStoreDetail">
          <div class="store-name">
            三里汇必胜客-营业时间(10:00-22:30)
            <span class="store-tag">切换餐厅</span>
          </div>
          <span class="arrow">></span>
        </div>
        <div class="store-address">五缘西三里1号101单元122、123铺位</div>
      </div>
    </section>

    <!-- 联系方式 -->
    <section class="contact-info">
      <h2 class="section-title">联系方式</h2>
      <div class="contact-form">
        <div class="form-item">
          <label class="required">联系电话</label>
          <input type="text" placeholder="请输入联系电话" v-model="phone">
        </div>
      </div>
    </section>

    <!-- 餐品信息 -->
    <section class="food-info">
      <h2 class="section-title">餐品信息</h2>
      <div class="food-card">
        <div class="food-item">
          <img src="https://pcp-pic.hwwt8.com/2/02014/images/500038397__ALL__S__20240709143514488.png" alt="照烧鸡肉炒饭" class="food-image">
          <div class="food-detail">
            <span class="food-name">照烧鸡肉炒饭</span>
            <span class="food-price">33.00元</span>
          </div>
        </div>
        <div class="action-buttons">
          <button class="btn-dine-in">类型堂食</button>
          <button class="btn-takeout">自取/外带/告知店员</button>
          <button class="btn-scan">桌码号查餐</button>
        </div>
      </div>
    </section>

    <!-- 底部操作栏 -->
    <div class="bottom-bar">
      <div class="bottom-left">
        <div class="service" @click="showService">
          <i class="icon-service"></i>
          <span>客服</span>
        </div>
        <div class="cart">
          <i class="icon-cart"></i>
          <span>购物车</span>
          <span class="cart-badge" v-if="cartCount">1</span>
        </div>
      </div>
      <button class="submit-btn" @click="submitOrder">提交订单</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestaurantPage',
  data() {
    return {
      phone: '',
      cartCount: 1
    }
  },
  methods: {
    goToStoreDetail() {
      console.log('跳转到店铺详情')
    },
    showService() {
      console.log('显示客服')
    },
    submitOrder() {
      // console.log('提交订单')
      this.$router.push('/orderPay')
    }
  }
}
</script>

<style scoped>
.restaurant-page {
  background: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 60px;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 15px;
  position: relative;
}

.section-title::before {
  content: '';
  width: 3px;
  height: 16px;
  background: #0066ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.store-card {
  background: white;
  margin: 0 15px;
  border-radius: 8px;
  padding: 15px;
}

.store-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.store-name {
  font-size: 16px;
  font-weight: bold;
}

.store-tag {
  font-size: 12px;
  color: white;
  background: #ff4d4f;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 10px;
}

.store-address {
  color: #666;
  font-size: 14px;
}

.contact-form {
  background: white;
  margin: 0 15px;
  border-radius: 8px;
  padding: 15px;
}

.form-item {
  display: flex;
  align-items: center;
}

.form-item label {
  width: 80px;
  color: #333;
}

.required::before {
  content: '*';
  color: #ff4d4f;
  margin-right: 4px;
}

.form-item input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
}

.food-card {
  background: white;
  margin: 0 15px;
  border-radius: 8px;
  padding: 15px;
}

.food-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.food-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.food-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.food-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.food-price {
  color: #ff4d4f;
  font-size: 16px;
  font-weight: bold;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-buttons button {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.btn-dine-in {
  background: #52c41a;
  color: white;
}

.btn-takeout {
  background: #faad14;
  color: white;
}

.btn-scan {
  background: #1890ff;
  color: white;
}

/* 新的底部栏样式 */
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.05);
}

.bottom-left {
  display: flex;
  gap: 20px;
}

.service, .cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #666;
  position: relative;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff4d4f;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn {
  height: 36px;
  background-color: #FF4D4F;
  color: white;
  border: none;
  border-radius: 18px;
  padding: 0 32px;
  font-size: 16px;
  cursor: pointer;
}
</style>