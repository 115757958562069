import Vue from 'vue'
import Toast from '../components/Toast.vue'

const ToastConstructor = Vue.extend(Toast)

function showToast(message, type = 'info', duration = 2000) {
  const toastInstance = new ToastConstructor({
    data: { message, type }
  })

  toastInstance.$mount()
  document.body.appendChild(toastInstance.$el)

  toastInstance.show(message, type, duration)
}

export default {
  install(Vue) {
    Vue.prototype.$toast = showToast
  }
}