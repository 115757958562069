<!-- PopupDialog.vue -->
<template>
  <div class="popup-container" v-if="visible">
    <div class="popup-mask"></div>
    <div class="popup-content">
      <!-- 标题 -->
      <h2 class="popup-title">温馨提示</h2>
      
      <!-- 提示文本 -->
      <div class="popup-text">
        <p>当您在店内就餐并通过本平台下单后，请不要使用官方 APP 或小程序加单哦。</p>
        <p>您可以在本平台显示出餐成功后再加单，否则将无法享受优惠点餐呢！</p>
        <p class="small-text">(这里的出餐成功是指：服务员已将您所点全部餐品的小票打印好并送到您手中呢。)</p>
      </div>

      <!-- 按钮区域 -->
      <div class="button-group">
        <button class="scan-btn" @click="handleScan">扫描二维码下单</button>
        <button class="upload-btn" @click="handleUpload">上传二维码下单</button>
      </div>

   
      <!-- 修改关闭按钮部分 -->
      <div class="close-btn" @click="handleClose">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="close-icon">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupDialog',
  data() {
    return {
      visible: true
    }
  },
  methods: {
    handleScan() {
      this.$emit('scan')
    },
    handleUpload() {
      this.$emit('upload')
    },
    handleClose() {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: relative;
  width: 85%;
  max-width: 320px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;
}

.popup-title {
  font-size: 18px;
  color: #FF4B4B;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;
}

.popup-text {
  color: #666666;
  text-align: center;
  line-height: 1.5;
}

.popup-text p {
  margin-bottom: 12px;
}

.small-text {
  font-size: 14px;
  color: #999999;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 12px;
}

.scan-btn,
.upload-btn {
  flex: 1;
  padding: 12px 0;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid;
  background: #FFFFFF;
  cursor: pointer;
}

.scan-btn {
  color: #FF6B00;
  border-color: #FF6B00;
}

.upload-btn {
  color: #4CAF50;
  border-color: #4CAF50;
}

.close-btn {
  position: absolute;
  left: 50%;
  bottom: -60px;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.close-btn img {
  width: 100%;
  height: 100%;
}
</style>