import axios from 'axios'

const service = axios.create({
  timeout: 15000
})

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 500) {
      return"";
    }
   
    return res
  },
  error => {
   
  }
)

export default service