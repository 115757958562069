<template>
  <div class="order-selection">
    <!-- 顶部横幅 -->
    <div class="banner">
      <img
        src="https://cdn.h7wl.com/bg.png"
        alt="披萨特写"
        class="banner-image"
      />
      <div class="banner-overlay">
        <h1 class="banner-title">奔赴新世盛一起约起来！</h1>
      </div>
    </div>

    <!-- 订餐选项 -->
    <div class="options-container">
      <h2 class="options-title">请选择【就餐方式】</h2>
      <div class="options-wrapper">
        <div class="option-card" @click="selectOption('dine-in')">
          <div class="option-icon">
            <img
              src="https://sares.pizzahut.com.cn/uatfile/ad/20230621/0a155d516b8648278e3e899f745650b8.webp"
              alt="店内就餐图标"
            />
          </div>
          <div class="option-content">
            <h3 class="option-title">店内就餐</h3>
            <p class="option-description">扫桌边二维码下单</p>
          </div>
        </div>

        <div class="option-card" @click="selectOption('takeaway')">
          <div class="option-icon">
            <img
              src="https://sares.pizzahut.com.cn/uatfile/ad/20221205/e0007d79f2b54b6ea62ac5dc833f8e0f.webp"
              alt="打包带走图标"
            />
          </div>
          <div class="option-content">
            <h3 class="option-title">打包带走</h3>
            <p class="option-description">到店自取</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加弹窗组件 -->
    <popup-dialog
      v-if="showPopup"
      @scan="handleScan"
      @upload="handleUpload"
      @close="handleClose"
    />

     <restaurant-selector
     
      :visible.sync="showSelector"
      :store-data="storeData"
      @confirm="handleStoreSelect"
    />
  </div>
</template>

<script>
import {
  codeVerification,
  orientation,
  getScanStatus,
  codeAndCookieApi,
} from "@/api/qrcode";
import PopupDialog from "./PopupDialog.vue";
import RestaurantSelector from '@/components/RestaurantSelector.vue'
export default {
  name: "OrderSelection",
  components: {
    PopupDialog,
    RestaurantSelector
  },

  methods: {
   async selectOption(option) {
      console.log("Selected option:", option);
      if (option === "dine-in") {
        this.showPopup = true; // 点击"店内就餐"时显示弹窗
      } else if (option === "takeaway") {
  
      console.log("1111111111111");  
      //查看餐厅
       const res = await orientation("40.057178", "116.408727");
       console.log(res);
       this.storeData=res;
           this.$nextTick(() => {
      this.showSelector = true;
      console.log('showSelector:', this.showSelector)
    })
   
        // this.$router.push({
        //   name: "FoodOrder",
        //   query: {
        //     type: "takeaway",
        //     code: this.code,
        //     format: this.format,
        //   },
        // });
        // 这里可以添加选择后的逻辑，比如页面跳转
        // this.$router.push(`/${option}`);
      }
    },
    // 弹窗相关方法
    handleScan() {
      // 处理扫描二维码逻辑
      console.log("扫描二维码");
      this.showPopup = false;
      // 存储数据到 Vuex
      this.$store.commit("setOrderData", {
        type: "dine-in",
        code: this.code,
        format: this.format,
      });
      this.$router.push({ name: "FoodOrder" });
    },
        // 处理门店选择
    handleStoreSelect(store) {
      console.log('选中的门店:', store)
      // 这里处理选中门店后的逻辑
    },
    handleUpload() {
      // 处理上传二维码逻辑
      console.log("上传二维码");
      this.showPopup = false;
      this.$store.commit("setOrderData", {
        type: "dine-in",
        code: this.code,
        format: this.format,
      });
      this.$router.push({ name: "FoodOrder" });
    },
    handleClose() {
      // 关闭弹窗
      this.showPopup = false;
    },
  },
  data() {
    return {
      format: "",
      code: "",
      showPopup: false,
      showSelector: false,
      storeData: {},
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.code = decodeURIComponent(urlParams.get("code") || "");
    this.format = decodeURIComponent(urlParams.get("format") || "");
    const res = await codeVerification(this.code, this.format);

    if (res.data === 0) {
    } else {
      this.$toast("卡劵已经过期", "error", 3000);
    }
  },
};
</script>
<style scoped>
.order-selection {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.banner {
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.banner-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin: 0;
  text-align: center;
}

.options-container {
  padding: 15px;
}

.options-title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}

.options-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.option-card {
  background-color: white;
  border-radius: 10px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  flex: 1;
  min-width: 0;
  max-width: 150px;
}

.option-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-icon img {
  width: 60px;
  height: 60px;
}

.option-content {
  text-align: center;
}

.option-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.option-description {
  font-size: 12px;
  color: #666;
  margin: 0;
}

@media (max-width: 360px) {
  .options-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .option-card {
    max-width: none;
    width: 80%;
  }
}
</style>