<template>
  <transition name="toast-fade">
    <div v-if="visible" class="toast" :class="type">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      visible: false,
      message: '',
      type: 'info',
      timeout: null
    }
  },
  methods: {
    show(message, type = 'info', duration = 2000) {
      this.visible = true
      this.message = message
      this.type = type

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.visible = false
      }, duration)
    }
  }
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 18px;
  color: #fff;
  font-size: 14px;
  z-index: 9999;
}

.info {
  background-color: #2196F3;
}

.success {
  background-color: #4CAF50;
}

.error {
  background-color: #F44336;
}

.toast-fade-enter-active, .toast-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.toast-fade-enter, .toast-fade-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px);
}
</style>

