// router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import OrderSelection from '../views/OrderSelection.vue'

import RestaurantPage from '../views/RestaurantPage.vue'
import orderPay from '../views/OrderPay.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/order-selection'  // 添加重定向
      },
      {
        path: '/order-selection',
        name: 'OrderSelection',
        component: OrderSelection
      },
      {
        path: '/food-order',
        name: 'FoodOrder',
        component: () => import('@/views/FoodOrder.vue'),
        meta: {
          keepAlive: true // 启用页面缓存
        }
      },  {
        path: '/restaurant-page',
        name: 'RestaurantPage',
        component: RestaurantPage
      },  {
        path: '/orderPay',
        name: 'OrderPay',
        component: orderPay
      }
    ]

const router = new VueRouter({
  routes
})

export default router