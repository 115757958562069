// src/api/qrcode.js
import request from './request'

export  async function codeVerification(code,format) {
    return request({
        url: '/api/bskcd/code/codeVerification',
        method: 'get',
        params: {
            code: code || '',  // 确保 code 不为 undefined
            format: format||'',
        }
    })
}
//获得餐厅
export  async function orientation(mylat,mylng) {
    return request({
        url: '/api/orientation/bsk',
        method: 'get',
        params: {
            mylat: mylat || '',  // 确保 code 不为 undefined
            mylng: mylng||'',
        }
    })
}

export  async function codeAndCookieApi(code, v,phone) {
    return request({
        url: 'api/baidu_dh',
        method: 'post',
        data: {
            code: code || '',
            token: v || '',
            phone: phone || ''
        }
    })
}

export async function getCookie(v) {
    try {
        const response = await request({
            url: `baidu/v3/login/main/qrbdusslogin?v=1732191184140&bduss=${v}&u=https%253A%252F%252Fpan.baidu.com%252Fdisk%252Fmain%253F_at_%253D1732190690188%2523%252Findex%253Fcategory%253Dall&loginVersion=v5&qrcode=1&tpl=netdisk&maskId=&fileId=&apiver=v3&tt=${Date.now()}&traceid=&time=${Date.now()}&alg=v3&sig=My81VXB2ZXE4djh2anVhUmJqZ3lkWHJHaXRUekh4K1ZPbEliRjNGZ00xT0NMSWwxMEhwczc0dDVFQnVVcUJhZg%3D%3D&elapsed=12&shaOne=00282a70c951a11c31550c3e97b2d4ac71076872&rinfo=%7B%22fuid%22%3A%22dbea9b925c3de371badadfd11d21b434%22%7D&callback=bd__cbs__al2v25`,
            method: 'get'
        });

        return response;
    } catch (error) {
        console.error('获取扫码状态失败:', error)
        throw error
    }
}

export async function getScanStatus(sign) {
    const callbackName = 'tangram_guid_1732191044667';
   
    try {
        const response = await request({
            url: `baidu/channel/unicast?channel_id=${sign}&gid=9EDCEBA-0C8A-4E55-829A-C34F59E5E4AF&tpl=netdisk&_sdkFrom=1&callback=${callbackName}&apiver=v3&tt=${Date.now()}&_=${Date.now()}`,
            method: 'get'
        });
 
        if(!response?.trim()) {
            return { v: '' };
        }
        
        // 处理JSON字符串
        let jsonStr = response.replace(`${callbackName}(`, '').replace(/\)$/, '').trim();
        if (jsonStr.endsWith(')')) {
            jsonStr = jsonStr.slice(0, -1);
        }
        
        try {
            const data = JSON.parse(jsonStr);
            
            if (data.channel_v) {
                const channelData = JSON.parse(data.channel_v);
                return { v: channelData.v || '' };
            }
        } catch (parseError) {
            console.warn('Parse response failed:', parseError);
        }
 
    } catch (error) {
        console.error('Request failed:', error);
    }
 
    return { v: '' };
 }