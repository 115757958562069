<template>
    <div class="page-container">
        <!-- 主体内容区域 -->
        <div class="main-content">
            <!-- 店铺信息卡片 -->
            <section class="info-card shop-card">
                <h2 class="section-title">门店信息</h2>
                <div class="shop-info">
                    <div class="shop-header">
                        <h3 class="shop-name">三里汇必胜客</h3>
                        <span class="shop-status">营业中</span>
                    </div>
                    <div class="shop-time">
                        <i class="icon-clock"></i>
                        <span>营业时间: 10:00-22:30</span>
                    </div>
                    <div class="shop-address">
                        <i class="icon-location"></i>
                        <span>五缘西三里1号101单元122、123铺位</span>
                    </div>
                    <div class="shop-id">
                        <i class="icon-id"></i>
                        <span>店铺编号: 2082375</span>
                    </div>
                </div>
            </section>

            <!-- 食品信息卡片 -->
            <section class="info-card food-card">
                <h2 class="section-title">食品信息</h2>
                <div class="food-info">
                    <div class="food-item">
                        <div class="food-image">
                            <img src="../assets/images/food.png" alt="照烧鸡肉炒饭" />
                        </div>
                        <div class="food-details">
                            <h4 class="food-name">照烧鸡肉炒饭</h4>
                            <div class="food-meta">
                                <span class="food-price">¥33.00</span>
                                <span class="food-quantity">x1</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-progress">
                        <steps 
                            :active-step="activeStep"
                            :steps="steps"
                            class="custom-steps"
                        />
                    </div>
                </div>
            </section>

            <!-- 订单信息卡片 -->
            <section class="info-card order-card">
                <h2 class="section-title">订单信息</h2>
                <div class="order-info">
                    <div v-for="(item, index) in orderInfoList" 
                         :key="index"
                         class="order-item">
                        <span class="item-label">{{ item.text }}</span>
                        <span class="item-value">{{ item.info }}</span>
                    </div>
                </div>
            </section>
        </div>

        <!-- 底部操作栏 -->
        <div class="bottom-bar">
            <div class="action-buttons">
                <button class="action-btn" @click="showService">
                    <i class="icon-service"></i>
                    <span>客服</span>
                </button>
                <button class="action-btn" @click="showCart">
                    <i class="icon-cart"></i>
                    <span>购物车</span>
                    <span v-if="cartCount" class="cart-badge">{{ cartCount }}</span>
                </button>
            </div>
            <button 
                class="submit-btn"
                :class="{ 'loading': isLoading }"
                @click="submitOrder"
                :disabled="isLoading"
            >
                {{ submitButtonText }}
            </button>
        </div>
    </div>
</template>

<script>
import steps from '@/components/steps.vue'

export default {
    name: 'OrderDetail',
    components: { steps },
    
    data() {
        return {
            isLoading: false,
            cartCount: 1,
            steps: [
                { 
                    title: '下单成功',
                    type: 'success',
                    description: '订单已接收' 
                },
                { 
                    title: '商家接单',
                    type: 'success',
                    description: '商家已确认订单' 
                },
                { 
                    title: '制作中',
                    type: 'primary',
                    description: '美食制作中' 
                },
                { 
                    title: '待支付',
                    type: 'warning',
                    description: '请尽快完成支付' 
                }
            ],
            activeStep: 3,
            orderInfoList: [
                { text: '订单号', info: '21549451655' },
                { text: '下单时间', info: '2024-10-01 16:33:33' },
                { text: '联系方式', info: '155****8541' },
            ]
        }
    },

    computed: {
        submitButtonText() {
            return this.isLoading ? '处理中...' : '订单支付'
        }
    },

    methods: {
        async submitOrder() {
            if (this.isLoading) return
            
            this.isLoading = true
            try {
                // 模拟支付请求
                await new Promise(resolve => setTimeout(resolve, 1500))
                this.$message.success('支付成功')
                this.activeStep = 4
            } catch (error) {
                this.$message.error('支付失败，请重试')
            } finally {
                this.isLoading = false
            }
        },

        showService() {
            // 实现客服功能
            this.$message.info('正在接入客服...')
        },

        showCart() {
            // 实现购物车功能
            this.$message.info('购物车功能开发中')
        }
    }
}
</script>

<style scoped>
.page-container {
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 16px 16px 66px;
    box-sizing: border-box;
}

.main-content {
    max-width: 800px;
    margin: 0 auto;
}

.info-card {
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.section-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0 0 16px;
    padding-left: 12px;
    border-left: 4px solid #ff4d4f;
}

/* 店铺信息样式 */
.shop-info {
    padding: 12px;
    background: #fafafa;
    border-radius: 8px;
}

.shop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.shop-name {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.shop-status {
    background: #52c41a;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
}

.shop-time,
.shop-address,
.shop-id {
    display: flex;
    align-items: center;
    margin: 8px 0;
    color: #666;
    font-size: 14px;
}

/* 食品信息样式 */
.food-item {
    display: flex;
    padding: 12px;
    background: #fafafa;
    border-radius: 8px;
    margin-bottom: 16px;
}

.food-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;
}

.food-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.food-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.food-name {
    font-size: 16px;
    margin: 0;
    color: #333;
}

.food-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.food-price {
    color: #ff4d4f;
    font-weight: 600;
    font-size: 16px;
}

.food-quantity {
    color: #999;
    font-size: 14px;
}

/* 订单信息样式 */
.order-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
}

.order-item:last-child {
    border-bottom: none;
}

.item-label {
    color: #666;
}

.item-value {
    color: #333;
    font-weight: 500;
}

/* 底部操作栏样式 */
.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.05);
}

.action-buttons {
    display: flex;
    gap: 24px;
}

.action-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    color: #666;
    position: relative;
}

.action-btn span {
    font-size: 12px;
    margin-top: 4px;
}

.cart-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    background: #ff4d4f;
    color: white;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 10px;
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-btn {
    height: 40px;
    background: #ff4d4f;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0 32px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
}

.submit-btn:hover {
    background: #ff7875;
}

.submit-btn:disabled {
    background: #ffccc7;
    cursor: not-allowed;
}

.submit-btn.loading {
    opacity: 0.8;
}

/* 自定义进度条样式 */
.custom-steps {
    margin: 24px 0;
}

/* 响应式调整 */
@media (max-width: 480px) {
    .page-container {
        padding: 12px 12px 66px;
    }

    .section-title {
        font-size: 16px;
    }

    .food-image {
        width: 60px;
        height: 60px;
    }

    .submit-btn {
        padding: 0 24px;
        font-size: 14px;
    }
}
</style>