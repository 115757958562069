import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderData: {
      type: '',
      code: '',
      format: ''
    }
  },
  mutations: {
    setOrderData(state, payload) {
      state.orderData = payload;
    },
    clearOrderData(state) {
      state.orderData = {
        type: '',
        code: '',
        format: ''
      };
    }
  }
})