// StoreSelector.vue
<template>
  <div class="store-selector" v-if="visible">
    <!-- 遮罩层 -->
    <div class="mask" @click="handleClose"></div>
    
    <!-- 弹出内容 -->
    <div class="popup">
      <!-- 头部 -->
      <div class="popup-header">
        <div class="title">选择餐厅</div>
        <div class="header-right">

          <span class="close-btn" @click="handleClose">×</span>
        </div>
      </div>

      <!-- 分类导航 -->
      <div class="tab-nav">
        <div 
          v-for="tab in tabs" 
          :key="tab.key"
          :class="['tab-item', { active: currentTab === tab.key }]"
          @click="currentTab = tab.key"
        >
          {{ tab.name }}
        </div>
      </div>

      <!-- 内容区域包装器 -->
      <div class="content-wrapper">
        <!-- 店铺列表 -->
        <div class="store-list">
          <div 
            v-for="store in displayStores" 
            :key="store.storecode"
            class="store-item"
            @click="handleSelect(store)" 
            :class="{ selected: selectedStore && selectedStore.storecode === store.storecode }"
          >
            <div class="store-info">
              <div class="store-name">
                {{ store.storename }}
                <span v-if="store.bookingMerge.boolBooking" class="tag">可预订</span>
              </div>
              <div class="store-address">{{ store.address }}</div>
              <div class="business-time">
                {{ store.starttime }} - {{ store.endtime }}
              </div>
            </div>
            <div class="store-right">
              <div class="distance">{{ formatDistance(store.distance) }}</div>

            </div>
          </div>
        </div>
      </div>

      <!-- 底部确认按钮 -->
      <div class="button-wrapper">
        <div 
          :class="['confirm-btn', { disabled: !selectedStore }]"
          @click="handleConfirm"
        >
          确认
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreSelector',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 初始化数据
    storeData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentTab: 'nearby',
      tabs: [
        { key: 'nearby', name: '附近餐厅' },
        { key: 'favorite', name: '常去&收藏' }
      ],
      selectedStore: null,
      storeList: []
    }
  },
  computed: {
    displayStores() {
      if (this.currentTab === 'favorite') {
        return this.storeList.filter(store => store.isFavorite)
      }
      return this.storeList
    }
  },
  watch: {
    storeData: {
      immediate: true,
      handler(val) {
        if(val?.data?.stores) {
          this.storeList = val.data.stores.map(store => ({
            ...store,
            isFavorite: false // 可以从本地存储或API获取收藏状态
          }))
        }
      }
    },
    visible(val) {
      if (!val) {
        this.selectedStore = null;
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleSelect(store) {
      this.selectedStore = store
    },
    handleConfirm() {
      if(!this.selectedStore) return
      this.$emit('confirm', this.selectedStore)
      this.handleClose()
    },
    toggleFavorite(store) {
      store.isFavorite = !store.isFavorite
      // 这里可以添加收藏/取消收藏的API调用
      this.$emit('favorite-change', {
        store,
        isFavorite: store.isFavorite
      })
    },
    formatDistance(distance) {
      if(!distance) return '未知距离'
      if(distance >= 1000) {
        return (distance/1000).toFixed(1) + 'km'
      }
      return Math.round(distance) + 'm'
    }
  }
}
</script>

<style scoped>
.store-selector {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
}

.popup {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 12px 12px 0 0;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.more-btn {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.user-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background: #eee;
  border-radius: 50%;
}

.close-btn {
  font-size: 24px;
  color: #999;
  line-height: 1;
  padding: 4px;
  cursor: pointer;
}

.tab-nav {
  display: flex;
  gap: 24px;
  padding: 0 16px 12px;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.tab-item {
  position: relative;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 4px 0;
}

.tab-item.active {
  color: #333;
  font-weight: 500;
}

.tab-item.active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  height: 2px;
  background: #e4393c;
}

.content-wrapper {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 16px;
}

.store-list {
  padding: 12px 0;
}

.store-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  border-radius: 8px;
  margin: 0 -16px;
  transition: all 0.3s ease;
}

.store-item:hover {
  background: #f9f9f9;
}

.store-item.selected {
  background: #FFF4F4;
  border: 1px solid #e4393c;
}

.store-info {
  flex: 1;
  margin-right: 12px;
}

.store-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.tag {
  font-size: 12px;
  color: #e4393c;
  border: 1px solid #e4393c;
  padding: 1px 6px;
  border-radius: 2px;
  margin-left: 8px;
  font-weight: normal;
}

.store-address {
  font-size: 13px;
  color: #666;
  margin-bottom: 6px;
}

.business-time {
  font-size: 13px;
  color: #999;
}

.store-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 60px;
}

.distance {
  font-size: 13px;
  color: #999;
}

.favorite {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-heart {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: relative;
}

.icon-heart:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: transparent;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.icon-heart.active {
  border-color: #e4393c;
  background: #e4393c;
}

.icon-heart.active:after {
  background: #fff;
}

.button-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #fff;
  border-top: 1px solid #f5f5f5;
  flex-shrink: 0;
}

.confirm-btn {
  height: 44px;
  background: #e4393c;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.confirm-btn:hover {
  background: #d4282b;
}

.confirm-btn.disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* 兼容移动端点击态样式 */
@media (max-width: 768px) {
  .store-item:active {
    background: #f9f9f9;
  }
  
  .confirm-btn:active {
    background: #d4282b;
  }
}
</style>