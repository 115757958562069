<template>
  <div id="app">
    <!-- router-view 用于显示路由匹配的组件 -->
        <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>

</template>

<script>
export default {
  name: 'App',
  created() {
    // 如果当前在根路径，重定向到 OrderSelection
    if (this.$route.path === '/') {
      this.$router.push('/order-selection')
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
}

/* 重置一些基础样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}
</style>