<template>
  <el-steps :active="activeStep" align-center>
    <el-step v-for="(step, index) in steps" :key="index">
      <template v-if="step.type" #title>
        <el-tag :type="step.type" effect="plain" round>
          {{ step.title }}{{ activeStep }}
        </el-tag>
      </template>
      <template v-else #title>
        {{ step.title }}
      </template>
      <template #description>
        <!-- 自定义描述内容 -->
        <div class="custom-description" :style="{ color: index + 1 === activeStep ? 'red' : '' }">
          {{ step.description }}
        </div>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
export default {
  props: ['activeStep', 'steps'],
  // data: () => {
  //   return {
  //     steps: [
  //       { title: '哈哈哈哈', type: 'primary', description: '你好' },
  //       { description: '有多好' },
  //       { title: '嘿嘿嘿嘿', type: 'success', description: '手打达到' },
  //       { description: '阿打发电饭' },
  //       { title: '嗯哼哼', type: 'danger', description: '阿萨大大是的' }
  //     ],
  //     activeStep: 3,
  //   }
  // }
}
</script>

<style scoped>
/* 步骤条 */
.steps {
  width: 80%;
  margin: 0 auto;
}

:deep(.el-step__head .el-step__icon .el-step__icon-inner) {
  display: none;
  /* 隐藏数字 */
}

:deep(.el-step__icon.is-text) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: none;
  background-color: #f60015;
  /* 设置为实心红色 */
  color: #c6a6a8;
  /* 文字颜色也设置为红色，确保图标颜色一致 */
  border: none;
  /* 移除边框，如果有的话 */
}

:deep(.el-step__line) {
  border-color: #f60015;
  position: absolute;
  margin-top: -2px;
}

/* 设置等待步骤的线条颜色为灰色 */
:deep(.el-step__head.is-wait) .el-step__line {
  background-color: #e3e3e3;
}

.custom-description {
  position: absolute;
  top: -66%;
  color: #a09e9e;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

/*隐藏.custom-description滚动条 */
.custom-description {
  /* 其他样式保持不变 */
  scrollbar-width: none; /* 对于Firefox */
  -ms-overflow-style: none; /* 对于Internet Explorer和Edge */
}

.custom-description::-webkit-scrollbar {
  display: none; /* 对于Chrome、Safari和Opera */
}
</style>
